import React from "react"
import Layout from "./layout"
import SEO from "./seo"

// function checkWebp(feature = "lossy", callback) {
//   var kTestImages = {
//     lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
//     lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
//     alpha:
//       "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
//     animation:
//       "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
//   }
//   var img = new Image()
//   img.onload = function () {
//     var result = img.width > 0 && img.height > 0
//     callback(feature, result)
//   }
//   img.onerror = function () {
//     callback(feature, false)
//   }
//   img.src = "data:image/webp;base64," + kTestImages[feature]
// }

export default function SinglePageHeader({
  title,
  children,
  bg,
  className,
  ...rest
}) {
  // const [bgImg, setBgImg] = useState(bg)
  // if (typeof window !== `undefined`) {
  //   checkWebp("lossy", (feature, isSupported) => {
  //     if (isSupported) {
  //       setBgImg(bg.substr(0, bg.lastIndexOf(".")) + ".webp")
  //     }
  //   })
  // }
  return (
    <Layout>
      <SEO title={title} />
      <div
        className="breadcrumb-area"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner">
                <h1 className="page-title">{title}</h1>
                <ul className="page-list"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main className={`ich-wrapper ${className || ""}`} {...rest}>
        {children}
      </main>
    </Layout>
  )
}
